import React, { useState, useEffect } from "react";
import BooksReadCard from "../Books Read Card/BooksReadCard";
import TimeCard from "../Time Card/TimeCard";
import { BounceLoader } from "react-spinners";
import WageEarnedCard from "../Wage Earned Card/WageEarnedCard";
import FitnessCard from "../Fitness Card/FitnessCard";
import { CSSTransition } from "react-transition-group";
import "./FortnitePage.css";
import FortniteDonut from "../Fortnite Donut/FortniteDonut";
import FortniteBarCard from "../Fortnite Bar Chart/FortniteBarChart";

const FortnitePage = (props: any) => {
  // eslint-disable-next-line
  const [daysBreakdown, setDaysBreakdown] = useState<number>();
  // eslint-disable-next-line
  const [hoursBreakdown, setHoursBreakdown] = useState<number>();
  // eslint-disable-next-line
  const [minutesBreakdown, setMinutesBreakdown] = useState<number>();
  const [totalMinutes, setTotalMinutes] = useState<number>();
  const [playModeDays, setPlayModeDays] = useState<any>();
  const [playModeMatches, setPlayModeMatches] = useState<any>();

  useEffect(() => {
    let total_minutes: number =
      props.duoMinutes +
      props.squadMinutes +
      props.soloMinutes +
      props.trioMinutes;
    setDaysBreakdown(Math.floor(total_minutes / (60 * 24)));
    setHoursBreakdown(Math.floor((total_minutes % (60 * 24)) / 60));
    setMinutesBreakdown(Math.floor(total_minutes % 60));
    setTotalMinutes(total_minutes);
    setPlayModeDays({
      duoDays: (props.duoMinutes / 60 / 24).toFixed(2),
      soloDays: (props.soloMinutes / 60 / 24).toFixed(2),
      trioDays: (props.trioMinutes / 60 / 24).toFixed(2),
      squadDays: (props.squadMinutes / 60 / 24).toFixed(2),
    });
    setPlayModeMatches({
      duoMatches: props.duoMatches,
      soloMatches: props.soloMatches,
      trioMatches: props.trioMatches,
      squadMatches: props.squadMatches,
    });
  }, [props]);

  const loading = () => {
    if (totalMinutes === 0) {
      return <BounceLoader color="#00f2c3"></BounceLoader>;
    } else {
      return (
        <div className="FortnitePage__master-div">
          <CSSTransition
            in={true}
            timeout={1000}
            classNames="FortnitePage__textone-transition"
            appear={true}
          >
            <h2 className="FortnitePage__text-one">You Spent</h2>
          </CSSTransition>
          <div className="FortnitePage__time-row">
            <CSSTransition
              in={true}
              timeout={2000}
              classNames="FortnitePage__days-transition"
              appear={true}
            >
              <TimeCard
                minutesPlayedNumber={totalMinutes}
                name="Days"
                gameName="Fortnite"
              ></TimeCard>
            </CSSTransition>

            <CSSTransition
              in={true}
              timeout={3000}
              classNames="FortnitePage__hours-transition"
              appear={true}
            >
              <TimeCard
                minutesPlayedNumber={totalMinutes}
                gameName="Fortnite"
                name="Hours"
              ></TimeCard>
            </CSSTransition>

            <CSSTransition
              in={true}
              timeout={4000}
              classNames="FortnitePage__minutes-transition"
              appear={true}
            >
              <TimeCard
                minutesPlayedNumber={totalMinutes}
                name="Minutes"
                gameName="Fortnite"
              ></TimeCard>
            </CSSTransition>
          </div>

          <CSSTransition
            in={true}
            timeout={5000}
            classNames="FortnitePage__texttwo-transition"
            appear={true}
          >
            <h2 className="FortnitePage__text-two">Playing Fortnite...</h2>
          </CSSTransition>
          <CSSTransition
            in={true}
            timeout={6000}
            classNames="FortnitePage__charts-transition"
            appear={true}
          >
            <div className="FortnitePage__charts-row">
              <FortniteDonut {...playModeDays}></FortniteDonut>

              <FortniteBarCard {...playModeMatches}></FortniteBarCard>
            </div>
          </CSSTransition>

          <CSSTransition
            in={true}
            timeout={6000}
            classNames="FortnitePage__charts-transition"
            appear={true}
          >
            <div className="FortnitePage__info-row">
              <WageEarnedCard
                minutesPlayedNumber={totalMinutes}
                gameName="Fortnite"
              ></WageEarnedCard>

              <BooksReadCard
                minutesPlayedNumber={totalMinutes}
                gameName="Fortnite"
              ></BooksReadCard>

              <FitnessCard
                minutesPlayedNumber={totalMinutes}
                gameName="Fortnite"
              ></FitnessCard>
            </div>
          </CSSTransition>
        </div>
      );
    }
  };

  return <div className="FortnitePage">{loading()}</div>;
};

export default FortnitePage;
