import React from "react";
import { Helmet } from "react-helmet-async";
import "./PageNotFound.css";

const PageNotFound = () => {
  return (
    <div className="PageNotFound">
      <Helmet>
        <title>404 error page not found</title>
        <meta name="description" content="Page not found."></meta>
      </Helmet>
      <img
        className="PageNotFound__img"
        src={require("../../images/404_Error.png")}
        alt="404 error image"
      ></img>
      <h1 className="PageNotFound__h1">This page does not exist!</h1>
    </div>
  );
};

export default PageNotFound;
