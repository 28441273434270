import React from "react";
import { Link } from "react-router-dom";
import "./NavBar.css";

const NavBar = () => {
  return (
    <div className="NavBar">
      <img
        style={{ height: "100%", width: "auto" }}
        className="NavBar__logo"
        src="time_wasted_logo_bg_secondary.png"
        alt="time wasted website logo"
      ></img>
      <ul
        className="NavBar__links"
        style={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
        }}
      >
        <li className="NavBar__list-item"></li>
        <li className="NavBar__list-item">
          <Link className="NavBar__link" to="/">
            Home
          </Link>
        </li>
        {/* <li>
                <Link className="NavBar__link" to="/">Donate</Link>
            </li> */}
        {/* <li className="NavBar__list-item">
          <Link className="NavBar__link" to="/guide">
            Guide
          </Link>
        </li> */}
        <li className="NavBar__list-item">
          <Link className="NavBar__link" to="/tools">
            Tools
          </Link>
        </li>
      </ul>
    </div>
  );
};

export default NavBar;
