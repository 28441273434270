import "./App.css";
import React, { useEffect, useState } from "react";
import InputCard from "./components/Input Card/InputTab";
import LoadingStats from "./components/Loading Stats Page/LoadingStats";
import { Helmet } from "react-helmet-async";

const App = () => {
  const [validSubmit, setValidSubmit] = useState<number>(-2);
  const [steamID, setSteamID] = useState<string>("");
  const [fortniteUsername, setFortniteUsername] = useState<string>("");

  useEffect(() => {
    setValidSubmit((submit) => submit + 1);
  }, [steamID]);

  useEffect(() => {
    setValidSubmit((submit) => submit + 1);
  }, [fortniteUsername]);

  return (
    // <Container fluid className="App">
    <div className="App_flexbox-div">
      <Helmet>
        <title>How much time have you wasted on Steam?</title>
        <meta
          name="description"
          content="Input your SteamID to see how much time you have wasted on Steam."
        ></meta>
        <link rel="canonical" href="/"></link>
      </Helmet>
      <div className="App__center-content-div">
        {validSubmit > 0 ? (
          <LoadingStats
            steamID={steamID}
            fortniteUsername={fortniteUsername}
          ></LoadingStats>
        ) : (
          <InputCard
            idSubmitted={setSteamID}
            fortniteUsernameSubmitted={setFortniteUsername}
          ></InputCard>
        )}
      </div>
    </div>
    // </Container>
  );
};

export default App;
