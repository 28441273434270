import React from "react";
import { Card } from "react-bootstrap";
import { addCommas } from "../../helpers/commas";
import { FaMoneyCheckAlt } from "react-icons/fa";
import { IconContext } from "react-icons";
import "./WageEarnedCard.css";

interface wageEarnedCardProps {
  minutes_played?: number[];
  gameName?: string;
  minutesPlayedNumber?: number;
}

const WageEarnedCard = (props: wageEarnedCardProps) => {
  let sum: number;
  if (props.gameName === "Fortnite") {
    sum = props.minutesPlayedNumber!;
  } else {
    sum = props.minutes_played!.reduce((partialSum, a) => partialSum + a, 0);
  }
  const wage = 15;
  let total_sum = addCommas(((sum / 60) * wage).toFixed(2).toString());

  return (
    <Card className="WageEarnedCard">
      <Card.Body className="WageEarnedCard__card-body">
        <div className="WageEarnedCard__text-holder">
          {" "}
          <div className="WageEarnedCard__top-text">
            <p className="WageEarnedCard__primary-text-left">
              Earnings Potential
            </p>
            <IconContext.Provider
              value={{
                style: {
                  fontSize: "20px",
                  color: "green",
                  // horizontalAlign: "middle",
                  // verticalAlign: "top",
                },
              }}
            >
              <FaMoneyCheckAlt></FaMoneyCheckAlt>
            </IconContext.Provider>
          </div>
          <div className="WageEarnedCard__card-centered-text-div">
            <p className="WageEarnedCard__secondary-text-left">
              You Could Have Earned
            </p>
            <h4 className="WageEarnedCard__swage-earned">{`$${total_sum}`}</h4>
            <p className="WageEarnedCard__secondary-text-center">
              At US Median Wages ($15 / hr)
            </p>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default WageEarnedCard;
