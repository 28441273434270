import React from "react";
import { Helmet } from "react-helmet-async";
import "./About.css";

const About = () => {
  return (
    <div className="About">
      <Helmet>
        <title>Why does this mytimewasted.com exist?</title>
        <meta
          name="description"
          content="mytimewasted.com shows how many hours users have wasted on Steam to help them change their habits."
        ></meta>
        <link rel="canonical" href="/about"></link>
      </Helmet>
      <div className="About__container-header-div">
        {" "}
        <div className="About__container-title-div">
          <h1 className="About__container-title-text">
            Why Does This Website Exist?
          </h1>
        </div>
        <img
          src={require("../../images/time.gif")}
          className="img-fluid shadow-4 About__hourglass-gif"
          alt="hourglass gif"
        ></img>
      </div>

      <p className="About__container-text-color">
        The average adult gamer spends nearly{" "}
        <a
          target="_blank"
          href="https://gamingsmart.com/average-gaming-playtime-report/#:~:text=Based%20on%20current%20data%2C%20the,roughly%201.2%20hours%20per%20day."
        >
          three years
        </a>{" "}
        of their lifetime playing video games. How many steam hours do you have?
        I was on course to meet this goal with over 100 days of playtime just on
        Steam while in my mid-twenties.
      </p>
      <p className="About__container-text-color">
        By raising awareness on how much playtime players have, if this website
        could persuade even a couple of people to spend some of their time more
        efficiently, I would consider it a success.
      </p>
      <h2 className="About__container-text-color">
        Video games are mostly harmless…
      </h2>
      <p className="About__container-text-color">
        There is nothing inherently wrong with playing video games. Watching TV,
        for example, is not an “upgrade” over playing video games. My issue with
        video games is that they can be very addictive. The opportunity cost of
        spending all your free time playing video games is real. Imagine if all
        that time you spent on video games was spent learning to code, working
        on an online business, hanging out with friends or family, or traveling
        the world?{" "}
      </p>
      <p className="About__container-text-color">
        When I was in my Freshman year of college, I racked up over{" "}
        <b className="About__bold">50 days</b> just playing League of Legends. I
        was completely addicted. In hindsight, if I had spent all that time
        working on an online business, studying, or continuing to teach myself
        to code, I would have given myself a significant headstart. But, at the
        end of the day, I had to ask myself this question:
      </p>
      <p className="About__container-text-color">
        <b className="About__bold">
          When I am on my deathbed, am I going to wish I had spent more time
          playing video games?
        </b>{" "}
        Or will I be happy knowing I spent my time traveling the world, earning
        financial freedom, or spending more time connecting with those I love?
      </p>

      <h2 className="About__container-text-color">
        Upcoming features for the site…
      </h2>
      <p className="About__container-text-color">
        Currently this website is just a side project for me, although I plan to
        add more features when I can, these include but are not limited to:
      </p>
      <ul>
        <li className="About__container-text-color">
          Expanding from just Steam to all major platforms and games.
        </li>
        <li className="About__container-text-color">
          Adding accounts or cookies so users can track their gaming progress
          over time across all platforms.
        </li>
        <li className="About__container-text-color">
          Developing the dashboard to help the user understand the opportunity
          cost of gaming by drilling down into specific categories.
        </li>
      </ul>
    </div>
  );
};

export default About;
