import React, { useEffect, useState } from "react";
import { BounceLoader } from "react-spinners";
import ChartsPage from "../Charts Page/ChartsPage";
import { prodAPIDict } from "../../helpers/prodAPIDict";
import { localAPIDict } from "../../helpers/localAPIDict";
import "./LoadingStats.css";
import FortnitePage from "../Fortnite Page/FortnitePage";
import { Button } from "react-bootstrap";

interface loadingStatsProps {
  steamID: string;
  fortniteUsername: string;
}

interface fortniteStatsInterface {
  duoMinutes: number;
  soloMinutes: number;
  squadMinutes: number;
  trioMinutes: number;
  duoMatches: number;
  soloMatches: number;
  squadMatches: number;
  trioMatches: number;
}

const LoadingStats = (props: loadingStatsProps) => {
  // const [initalRender, setInitialRender] = useState(0);
  const [games, setGames] = useState<any[]>([]);
  const [fortniteStats, setFortniteStats] = useState<any>();
  const [realFortniteId, setRealFortniteId] = useState<boolean | null>();
  const [realSteamId, setRealSteamId] = useState<boolean | null>();

  useEffect(() => {
    //fetch owned games and play time
    let env_url = window.location.hostname.includes("localhost")
      ? localAPIDict.env_url
      : prodAPIDict.env_url;
    if (props.steamID !== "") {
      fetch(`${env_url}/steam/user/${props.steamID}`)
        .then((data) => data.json())
        .then((data) => {
          if (data.result === false) {
            setRealSteamId(false);
          } else {
            var games = JSON.parse(data);
            // setInitialRender((count) => count + 1)
            setGames(games);
            setRealSteamId(true);
          }
        });
    } else if (props.fortniteUsername !== "") {
      fetch(`${env_url}/fortnite/getuserinfo/${props.fortniteUsername}`)
        .then((data) => data.json())
        .then((data) => {
          console.log(data);
          if (data.result === false) {
            setRealFortniteId(false);
          } else {
            const duoMinutes: number = data.global_stats.duo.minutesplayed;
            const soloMinutes: number = data.global_stats.solo.minutesplayed;
            const squadMinutes: number = data.global_stats.squad.minutesplayed;
            const trioMinutes: number = data.global_stats.trio.minutesplayed;
            const duoMatches: number = data.global_stats.duo.matchesplayed;
            const soloMatches: number = data.global_stats.solo.matchesplayed;
            const squadMatches: number = data.global_stats.squad.matchesplayed;
            const trioMatches: number = data.global_stats.trio.matchesplayed;
            setFortniteStats({
              duoMinutes: duoMinutes,
              soloMinutes: soloMinutes,
              squadMinutes: squadMinutes,
              trioMinutes: trioMinutes,
              duoMatches: duoMatches,
              soloMatches: soloMatches,
              squadMatches: squadMatches,
              trioMatches: trioMatches,
            });
            setRealFortniteId(true);
          }
        });
    } else {
    }
  }, []);

  const refreshPage = () => {
    window.location.reload();
  };

  const games_loading = () => {
    console.log(games);
    if (realSteamId) {
      return <ChartsPage games={games}></ChartsPage>;
    } else if (realSteamId === false && props.steamID !== "") {
      return (
        <div className="LoadingStats_button-div">
          <Button className="LoadingStats__return-button" onClick={refreshPage}>
            This username was not found. Try another?
          </Button>
        </div>
      );
    } else {
      return (
        <div className="LoadingStats-div">
          <BounceLoader color="#00f2c3"></BounceLoader>
        </div>
      );
    }
  };

  const fortniteLoading = () => {
    if (realFortniteId) {
      return <FortnitePage {...fortniteStats}></FortnitePage>;
    } else if (realFortniteId === false && props.fortniteUsername !== "") {
      return (
        <div className="LoadingStats_button-div">
          <Button className="LoadingStats__return-button" onClick={refreshPage}>
            This username was not found. Try another?
          </Button>
        </div>
      );
    } else {
      return (
        <div className="LoadingStats-div">
          <BounceLoader color="#00f2c3"></BounceLoader>
        </div>
      );
    }
  };

  // const games_loading = this.games_loading;
  return (
    <div className="LoadingStats">
      {props.steamID !== "" ? games_loading() : fortniteLoading()}
    </div>
  );
};

export default LoadingStats;
