import { useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import "./SteamCard.css";

interface SteamCardProps {
  idSubmitted: React.Dispatch<React.SetStateAction<string>>;
}

const SteamCard = (props: SteamCardProps) => {
  const [val, setVal] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("steamID");
    let initialValue: string;
    if (saved !== null) {
      initialValue = JSON.parse(saved);
    } else {
      initialValue = "";
    }
    return initialValue;
  });

  const onSubmit = (event: any) => {
    localStorage.setItem("steamID", JSON.stringify(val));
    event.preventDefault();
    props.idSubmitted(val);
  };

  return (
    // <Container fluid className="App">
    <Card className="SteamCard border-0">
      <Card.Body>
        <h1 className="SteamCard__main-text">
          How much time have I spent on steam?
        </h1>
        <br></br>
        <Form className="SteamCard__account-inputs">
          <Form.Group controlId="formSteamID">
            <Form.Label>Enter your Steam ID</Form.Label>
            <Form.Control
              type="text"
              placeholder="Steam ID"
              onChange={(e) => setVal(e.target.value)}
              value={val}
            ></Form.Control>
            <Form.Text className="text-muted">
              Your Steam ID is the 17 digit number found on your Steam profile
              tab.
            </Form.Text>
          </Form.Group>
          <div className="text-align-center SteamCard__button-holder">
            <Button
              className="btn-fill btn SteamCard__btn-primary"
              variant="primary"
              type="submit"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default SteamCard;
