import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./Donut.css";

ChartJS.register(ArcElement, Tooltip, Legend, Filler, ChartDataLabels);

interface donutProps {
  games: (string | number)[];
  days_played: number[];
}

const Donut = (props: donutProps) => {
  const pie_colors = [
    "#e14eca",
    "#1d8cf8",
    "#00f2c3",
    "#ff8d72",
    "#fd5d93",
    "#008398",
    "#007954",
    "#8305FC",
    "#4AF707",
    "#F2F714",
  ];

  var chart_data = {
    labels: props.games,
    datasets: [
      {
        label: "Playtime in Days",
        data: props.days_played,
        backgroundColor: pie_colors,
        borderColor: "#27293d",
      },
    ],
  };

  return (
    <Card className="Donut">
      <Card.Body className="Donut__card-body">
        <div className="Donut__card-text-div">
          <p className="Donut__primary-text-left">
            Days Played Breakdown by Game
          </p>
        </div>
        <div className="Donut__chart-div">
          <Doughnut
            data={chart_data}
            options={{
              responsive: true,
              maintainAspectRatio: false,
              plugins: {
                title: {
                  display: false,
                  text: "Playtime in Days",
                  color: "#fff",
                },
                legend: {
                  display: false,
                },
                tooltip: {
                  callbacks: {
                    label: function (context) {
                      let label = context.label || "";

                      if (label) {
                        label += ": ";
                      }
                      if (context.parsed !== null) {
                        label += `${context.parsed} Days Played`;
                      }
                      return label;
                    },
                  },
                },
                datalabels: {
                  color: "black",
                  display: "auto",
                },
              },
            }}
          />
        </div>
      </Card.Body>
    </Card>
  );
};

export default Donut;
