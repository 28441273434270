import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import "./ToolCard.css";

interface toolCardProps {
  tool: string
  toolType: string
  rating: string
  toolDetails: string
  toolLinkText?: string
  toolLink?: string
}

const ToolCard = (props: toolCardProps) => {
  const [color, SetColor] = useState("white");

  useEffect(() => {
    if (props.toolType === "Book") {
      SetColor("#1d8cf8");
    } else if (props.toolType === "Software") {
      SetColor("#e14eca");
    } else {
      SetColor("#00f2c3");
    }
  }, []);

  return (
    <Card className="ToolCard" style={{ border: `1px solid ${color}` }}>
      <Card.Body className="ToolCard__body">
        <Card.Title style={{ color: `${color}` }}>{props.tool}</Card.Title>
        <Card.Subtitle className="mb-2 text-muted">
          {props.toolType}
        </Card.Subtitle>
        <Card.Subtitle className="mb-2 text-muted">
          {props.rating}
        </Card.Subtitle>
        <Card.Text>{props.toolDetails}</Card.Text>
        <Card.Link href={props.toolLink}>{props.toolLinkText}</Card.Link>
      </Card.Body>
    </Card>
  );
};

export default ToolCard;
