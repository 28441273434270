import React from "react";
import { Doughnut } from "react-chartjs-2";
import { Card } from "react-bootstrap";
import {
  Chart as ChartJS,
  ArcElement,
  Tooltip,
  Legend,
  Filler,
} from "chart.js";
import ChartDataLabels from "chartjs-plugin-datalabels";
import "./FortniteDonut.css";
import { BounceLoader } from "react-spinners";

ChartJS.register(ArcElement, Tooltip, Legend, Filler, ChartDataLabels);

const FortniteDonut = (props: any) => {
  const daysArray: number[] = [
    props.soloDays,
    props.duoDays,
    props.trioDays,
    props.squadDays,
  ];
  const daysArrayLabels: string[] = ["Solo", "Duo", "Trio", "Squad"];

  const pie_colors = [
    "#e14eca",
    "#1d8cf8",
    "#00f2c3",
    "#ff8d72",
    "#fd5d93",
    "#008398",
    "#007954",
    "#8305FC",
    "#4AF707",
    "#F2F714",
  ];

  var chart_data = {
    labels: daysArrayLabels,
    datasets: [
      {
        label: "Playtime in Days",
        data: daysArray,
        backgroundColor: pie_colors,
        borderColor: "#27293d",
      },
    ],
  };

  if (
    props.soloDays > 0 ||
    props.duoDays > 0 ||
    props.trioDays > 0 ||
    props.squadDays > 0
  ) {
    return (
      <Card className="FortniteDonut">
        <Card.Body className="FortniteDonut__card-body">
          <div className="FortniteDonut__card-text-div">
            <p className="FortniteDonut__primary-text-left">
              Days Played Breakdown by Game Mode
            </p>
          </div>
          <div className="FortniteDonut__chart-div">
            <Doughnut
              data={chart_data}
              options={{
                responsive: true,
                maintainAspectRatio: false,
                plugins: {
                  title: {
                    display: false,
                    text: "Playtime in Days",
                    color: "#fff",
                  },
                  legend: {
                    display: false,
                  },
                  tooltip: {
                    callbacks: {
                      label: function (context) {
                        let label = context.label || "";

                        if (label) {
                          label += ": ";
                        }
                        if (context.parsed !== null) {
                          label += `${context.parsed} Days Played`;
                        }
                        return label;
                      },
                    },
                  },
                  datalabels: {
                    color: "black",
                    display: "auto",
                  },
                },
              }}
            />
          </div>
        </Card.Body>
      </Card>
    );
  } else {
    return <BounceLoader color="#00f2c3"></BounceLoader>;
  }
};

export default FortniteDonut;
