import React from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

// I can use a functional component here because the footer has no state
const Footer = () => {
  return (
    <div className="Footer">
      <div className="Footer__div">
        {" "}
        {/* <Link className="Footer__link" to="/">
          Home
        </Link> */}
        <Link className="Footer__link" to="/about">
          About
        </Link>
        {/* <Link className="Footer__link" to="/guide">
          Guide
        </Link>
        <Link className="Footer__link" to="/tools">
          Tools
        </Link> */}
        <a target="_blank" href="https://www.buymeacoffee.com/mytimewasted">
          Buy Me a Coffee
        </a>
      </div>

      <div className="Footer__copyright-div">
        {" "}
        <p className="Footer__text">© 2022 My-Time-Wasted by Weston</p>
      </div>
    </div>
  );
};

export default Footer;

{
  /* <Link className="Footer__link" to="/">
Link 1 | {""}
</Link>
<Link className="Footer__link" to="/">
Link 1
</Link> */
}
