import React from "react";
import { Card } from "react-bootstrap";
import { FaBook } from "react-icons/fa";
import { IconContext } from "react-icons";
import "./BooksReadCard.css";

interface booksReadCardProps {
  games?: (string | number)[];
  days_played?: number[];
  gameName?: string;
  minutesPlayedNumber?: number;
}

const BooksReadCard = (props: booksReadCardProps) => {
  const time_to_read_book = 0.2334267; //5.6 hours
  let sum: number;
  if (props.gameName === "Fortnite") {
    sum = props.minutesPlayedNumber! / (60 * 24);
  } else {
    sum = props.days_played!.reduce((partialSum, a) => partialSum + a, 0);
  }
  let total_books = Number(sum / time_to_read_book).toFixed(1);

  return (
    <Card className="BooksReadCard">
      <Card.Body className="BooksReadCard__card-body">
        <div className="BooksReadCard__card-body-div">
          {" "}
          <div className="BooksReadCard__top-text">
            <p className="BooksReadCard__card-text-left">Learning Potential</p>
            <IconContext.Provider
              value={{
                style: {
                  fontSize: "20px",
                  color: "#e14eca",
                  // horizontalAlign: "middle",
                  // verticalAlign: "top",
                },
              }}
            >
              <FaBook></FaBook>
            </IconContext.Provider>
          </div>
          <div className="BooksReadCard__card-body-div2">
            <p className="BooksReadCard__card-text-left-secondary">
              You Could Have Read
            </p>

            <h4 className="BooksReadCard__card-books-read">
              {total_books} Books
            </h4>
            <p className="BooksReadCard__card-text-center-secondary">
              Spending 5.6 Hours Per Book
            </p>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default BooksReadCard;
