import React from "react";
import { Card } from "react-bootstrap";
import { FaRunning } from "react-icons/fa";
import { IconContext } from "react-icons";
import "./FitnessCard.css";

interface fitnessCardProps {
  games?: (string | number)[];
  days_played?: number[];
  gameName?: string;
  minutesPlayedNumber?: number;
}

const FitnessCard = (props: fitnessCardProps) => {
  const time_to_walk_mile = 0.01215278; // 17.5 minutes
  let sum: number;
  if (props.gameName === "Fortnite") {
    sum = props.minutesPlayedNumber! / (60 * 24);
  } else {
    sum = props.days_played!.reduce((partialSum, a) => partialSum + a, 0);
  }
  let total_miles = Number(sum / time_to_walk_mile);
  let times_walked_across_usa = Number(total_miles / 2800).toFixed(2);

  return (
    <Card className="FitnessCard">
      <Card.Body className="FitnessCard__card-body">
        <div className="FitnessCard__text-holder-div">
          <div className="FitnessCard__top-text">
            {" "}
            <p className="FitnessCard__primary-text-left">Fitness Potential</p>
            <IconContext.Provider
              value={{
                style: {
                  fontSize: "20px",
                  color: "#1d8cf8",
                  // horizontalAlign: "middle",
                  // verticalAlign: "top",
                },
              }}
            >
              <FaRunning></FaRunning>
            </IconContext.Provider>
          </div>
          <div className="FitnessCard__card-centered-text-div">
            <p className="FitnessCard__secondary-text-left">
              You Could Have Walked Across the US
            </p>
            <h4 className="FitnessCard__miles-walked-text">
              {times_walked_across_usa} Times
            </h4>
            <p className="FitnessCard__secondary-text-center">
              Coast to Coast Distance of 2800 Miles
            </p>
          </div>
        </div>
      </Card.Body>
    </Card>
  );
};

export default FitnessCard;
