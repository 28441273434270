import React, { useState, useEffect } from "react";
import { Col, Nav, Row, Tab } from "react-bootstrap";
import FortniteCard from "../Fortnite Card/FortniteCard";
import SteamCard from "../Steam Card/SteamCard";
import "./InputTab.css";

interface inputCardProps {
  idSubmitted: React.Dispatch<React.SetStateAction<string>>;
  fortniteUsernameSubmitted: React.Dispatch<React.SetStateAction<string>>;
}

const InputCard = (props: inputCardProps) => {
  return (
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={3}>
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="first">Steam</Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">Fortnite</Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        <Col sm={9}>
          <Tab.Content>
            <Tab.Pane eventKey="first">
              <SteamCard idSubmitted={props.idSubmitted}></SteamCard>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
              <FortniteCard
                idSubmitted={props.fortniteUsernameSubmitted}
              ></FortniteCard>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
  );
};

export default InputCard;
