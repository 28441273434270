import { useState } from "react";
import { Button, Card, Container, Form } from "react-bootstrap";
import "./FortniteCard.css";

interface FortniteCardProps {
  idSubmitted: React.Dispatch<React.SetStateAction<string>>;
}

const FortniteCard = (props: FortniteCardProps) => {
  const [val, setVal] = useState(() => {
    // getting stored value
    const saved = localStorage.getItem("fortniteUsername");
    let initialValue: string;
    if (saved !== null) {
      initialValue = JSON.parse(saved);
    } else {
      initialValue = "";
    }
    return initialValue;
  });

  const onSubmit = (event: any) => {
    localStorage.setItem("fortniteUsername", JSON.stringify(val));
    event.preventDefault();
    props.idSubmitted(val);
  };

  return (
    // <Container fluid className="App">
    <Card className="FortniteCard border-0">
      <Card.Body>
        <h1 className="FortniteCard__main-text">
          How much time have I spent on Fortnite?
        </h1>
        <br></br>
        <Form className="FortniteCard__account-inputs">
          <Form.Group controlId="formSteamID">
            <Form.Label>Enter your Fortnite username</Form.Label>
            <Form.Control
              type="text"
              placeholder="Fortnite Username"
              onChange={(e) => setVal(e.target.value)}
              value={val}
            ></Form.Control>
          </Form.Group>
          <div className="text-align-center FortniteCard__button-holder">
            <Button
              className="btn-fill btn FortniteCard__btn-primary"
              variant="primary"
              type="submit"
              onClick={onSubmit}
            >
              Submit
            </Button>
          </div>
        </Form>
      </Card.Body>
    </Card>
  );
};

export default FortniteCard;
