import React from "react";
import { Card } from "react-bootstrap";
import "./TimeCard.css";

interface timeCardProps {
  minutes_played?: number[];
  name: string;
  gameName?: string;
  minutesPlayedNumber?: number;
}

const TimeCard = (props: timeCardProps) => {
  let sum: number;
  if (props.gameName === "Fortnite") {
    sum = props.minutesPlayedNumber!;
  } else {
    sum = props.minutes_played!.reduce((partialSum, a) => partialSum + a, 0);
  }

  let days_breakdown = Math.floor(sum / (60 * 24));
  let hours_breakdown = Math.floor((sum % (60 * 24)) / 60);
  let minutes_breakdown = Math.floor(sum % 60);

  return (
    <Card className="TimeCard">
      <Card.Body className="TimeCard__body">
        <div className="TimeCard__text-holder">
          <h4 className="TimeCard-text">
            {time_chooser(
              props.name,
              days_breakdown,
              hours_breakdown,
              minutes_breakdown
            )}{" "}
            {props.name}
          </h4>
        </div>
      </Card.Body>
    </Card>
  );
};

const time_chooser = (
  name: string,
  days_breakdown: number,
  hours_breakdown: number,
  minutes_breakdown: number
) => {
  if (name === "Days") {
    return days_breakdown;
  } else if (name === "Hours") {
    return hours_breakdown;
  } else {
    return minutes_breakdown;
  }
};

export default TimeCard;
