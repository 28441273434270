import React, { useState, useEffect } from "react";
import BooksReadCard from "../Books Read Card/BooksReadCard";
import TimeCard from "../Time Card/TimeCard";
import { BounceLoader } from "react-spinners";
import Donut from "../Donut Card/Donut";
import TopGameCard from "../Top Game Card/TopGameCard";
import WageEarnedCard from "../Wage Earned Card/WageEarnedCard";
import FitnessCard from "../Fitness Card/FitnessCard";
import { CSSTransition } from "react-transition-group";
import "./ChartsPage.css";

interface chartsPageProps {
  games: any[];
}

const ChartsPage2 = (props: chartsPageProps) => {
  const [playTimeMinutes, setPlayTimeMinutes] = useState<number[]>([]);
  // eslint-disable-next-line
  const [playTimeHours, setPlayTimeHours] = useState<(string | number)[]>([]);
  const [playTimeDays, setPlayTimeDays] = useState<number[]>([]);
  const [games, setGames] = useState<(string | number)[]>([]);
  // eslint-disable-next-line
  const [gameCount, setGameCount] = useState<string | number>(0);
  const [appIds, setAppIds] = useState<number[]>([]);
  // eslint-disable-next-line
  const [daysBreakdown, setDaysBreakdown] = useState<(string | number)[]>([]);
  // eslint-disable-next-line
  const [hoursBreakdown, setHoursBreakdown] = useState<(string | number)[]>([]);
  // eslint-disable-next-line
  const [minutesBreakdown, setMinutesBreakdown] = useState<(string | number)[]>(
    []
  );
  const [imageLogos, setImageLogos] = useState<string[]>([]);

  useEffect(() => {
    let days_breakdown: (string | number)[] = [];
    let hours_breakdown: (string | number)[] = [];
    let minutes_breakdown: (string | number)[] = [];
    let time_minutes: number[] = [];
    let time_hours: number[] = [];
    let time_days: number[] = [];
    let games: string[] = [];
    let app_ids: number[] = [];
    let image_logos: string[] = [];

    for (var i = 0; i < props.games.length; i++) {
      games.push(props.games[i].name);
      app_ids.push(props.games[i].appid);
      time_minutes.push(props.games[i].playtime_forever);
      time_hours.push(
        Number((parseInt(props.games[i].playtime_forever) / 60).toFixed(2))
      );
      time_days.push(
        Number(
          (parseInt(props.games[i].playtime_forever) / (60 * 24)).toFixed(2)
        )
      );
      days_breakdown.push(
        Math.floor(parseInt(props.games[i].playtime_forever) / (60 * 24))
      );
      hours_breakdown.push(
        Math.floor((parseInt(props.games[i].playtime_forever) % (60 * 24)) / 60)
      );
      minutes_breakdown.push(
        Math.floor(parseInt(props.games[i].playtime_forever) % 60)
      );
      image_logos.push(props.games[i].img_icon_url);
    }
    let arrayOfObj = games.map(function (d, i) {
      return {
        games: d,
        app_ids: app_ids[i] || 0,
        time_hours: time_hours[i] || 0,
        time_days: time_days[i] || 0,
        time_minutes: time_minutes[i] || 0,
        days_breakdown: days_breakdown[i] || 0,
        hours_breakdown: hours_breakdown[i] || 0,
        minutes_breakdown: minutes_breakdown[i] || 0,
        image_logos: image_logos[i],
      };
    });

    let sortedArrayOfObj = arrayOfObj.sort(function (a, b) {
      return a.time_minutes - b.time_minutes;
    });

    let new_games: (string | number)[] = [];
    let new_time_hours: (string | number)[] = [];
    let new_time_minutes: number[] = [];
    let new_time_days: number[] = [];
    let new_days_breakdown: (string | number)[] = [];
    let new_hours_breakdown: (string | number)[] = [];
    let new_minutes_breakdown: (string | number)[] = [];
    let new_app_ids: number[] = [];
    let new_image_logos: string[] = [];

    sortedArrayOfObj.forEach(function (d) {
      new_games.push(d.games);
      new_app_ids.push(d.app_ids);
      new_time_hours.push(d.time_hours);
      new_time_minutes.push(d.time_minutes);
      new_time_days.push(d.time_days);
      new_days_breakdown.push(d.days_breakdown);
      new_hours_breakdown.push(d.hours_breakdown);
      new_minutes_breakdown.push(d.minutes_breakdown);
      new_image_logos.push(d.image_logos);
    });

    setPlayTimeMinutes(new_time_minutes);
    setPlayTimeHours(new_time_hours);
    setPlayTimeDays(new_time_days);
    setGames(new_games);
    setAppIds(new_app_ids);
    setGameCount(games.length);
    setDaysBreakdown(new_days_breakdown);
    setHoursBreakdown(new_hours_breakdown);
    setMinutesBreakdown(new_minutes_breakdown);
    setImageLogos(new_image_logos);
  }, [props.games]);

  const loading = () => {
    if (playTimeDays.length === 0) {
      return <BounceLoader color="#00f2c3"></BounceLoader>;
    } else {
      return (
        <div className="ChartsPage__master-div">
          <CSSTransition
            in={true}
            timeout={1000}
            classNames="ChartsPage__textone-transition"
            appear={true}
          >
            <h2 className="ChartsPage__text-one">You Spent</h2>
          </CSSTransition>
          <div className="ChartsPage__time-row">
            <CSSTransition
              in={true}
              timeout={2000}
              classNames="ChartsPage__days-transition"
              appear={true}
            >
              <TimeCard minutes_played={playTimeMinutes} name="Days"></TimeCard>
            </CSSTransition>

            <CSSTransition
              in={true}
              timeout={3000}
              classNames="ChartsPage__hours-transition"
              appear={true}
            >
              <TimeCard
                minutes_played={playTimeMinutes}
                name="Hours"
              ></TimeCard>
            </CSSTransition>

            <CSSTransition
              in={true}
              timeout={4000}
              classNames="ChartsPage__minutes-transition"
              appear={true}
            >
              <TimeCard
                minutes_played={playTimeMinutes}
                name="Minutes"
              ></TimeCard>
            </CSSTransition>
          </div>

          <CSSTransition
            in={true}
            timeout={5000}
            classNames="ChartsPage__texttwo-transition"
            appear={true}
          >
            <h2 className="ChartsPage__text-two">Playing Games on Steam...</h2>
          </CSSTransition>
          <div className="ChartsPage__charts-row">
            <CSSTransition
              in={true}
              timeout={6000}
              classNames="ChartsPage__charts-transition"
              appear={true}
            >
              <Donut games={games} days_played={playTimeDays}></Donut>
            </CSSTransition>

            <CSSTransition
              in={true}
              timeout={6000}
              classNames="ChartsPage__charts-transition"
              appear={true}
            >
              <TopGameCard
                minutes_played={playTimeMinutes}
                ids={appIds}
                image_urls={imageLogos}
              ></TopGameCard>
            </CSSTransition>
          </div>
          <div className="ChartsPage__info-row">
            <CSSTransition
              in={true}
              timeout={6000}
              classNames="ChartsPage__charts-transition"
              appear={true}
            >
              <WageEarnedCard minutes_played={playTimeMinutes}></WageEarnedCard>
            </CSSTransition>

            <CSSTransition
              in={true}
              timeout={6000}
              classNames="ChartsPage__charts-transition"
              appear={true}
            >
              <BooksReadCard
                games={games}
                days_played={playTimeDays}
              ></BooksReadCard>
            </CSSTransition>

            <CSSTransition
              in={true}
              timeout={6000}
              classNames="ChartsPage__charts-transition"
              appear={true}
            >
              <FitnessCard
                games={games}
                days_played={playTimeDays}
              ></FitnessCard>
            </CSSTransition>
          </div>
        </div>
      );
    }
  };

  return <div className="ChartsPage">{loading()}</div>;
};

export default ChartsPage2;
