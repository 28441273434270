import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import { BounceLoader } from "react-spinners";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { localAPIDict } from "../../helpers/localAPIDict";
import { prodAPIDict } from "../../helpers/prodAPIDict";
import "./TopGameCard.css";

ChartJS.register(
  CategoryScale,
  Tooltip,
  Legend,
  LinearScale,
  BarElement,
  Title,
  ChartDataLabels
);

interface topGameCardProps {
  minutes_played: number[];
  ids: number[];
  image_urls: string[];
}

const TopGameCard = (props: topGameCardProps) => {
  const [game, setGame] = useState<string>("");
  const [medianMinutesPlayed, setMedianMinutesPlayed] = useState<number>(0);
  const [averageMinutesPlayed, setAverageMinutesPlayed] = useState<number>(0);
  const [userTotalMinutesPlayed, setUserTotalMinutesPlayed] =
    useState<number>(0);
  const [gameImage, setGameImage] = useState<string>("");
  const [gameId, setGameId] = useState<number>(0);
  const [gameImageUrl, setGameImageUrl] = useState<string>("");

  useEffect(() => {
    var env_url = window.location.hostname.includes("localhost")
      ? localAPIDict.env_url
      : prodAPIDict.env_url;
    fetch(`${env_url}/steam/appinfo/${props.ids[props.ids.length - 1]}`)
      .then((data) => data.json())
      .then((data) => {
        var top_game_minutes =
          props.minutes_played[props.minutes_played.length - 1];
        var top_game_image = props.image_urls[props.image_urls.length - 1];
        var top_game_id = props.ids[props.ids.length - 1];
        var game_image_url = `http://media.steampowered.com/steamcommunity/public/images/apps/${top_game_id}/${top_game_image}.jpg`;

        setGame(data.name);
        setMedianMinutesPlayed(data.median_forever);
        setAverageMinutesPlayed(data.average_forever);
        setUserTotalMinutesPlayed(top_game_minutes);
        setGameImage(top_game_image);
        setGameId(top_game_id);
        setGameImageUrl(game_image_url);
      });
  }, []);

  let sum = props.minutes_played.reduce((partialSum, a) => partialSum + a, 0);
  const wage = 15;
  let total_sum = ((sum / 60) * wage).toFixed(2);

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
        position: "top",
      },
      title: {
        display: false,
        text: "Top Played Game",
      },
      datalabels: {
        color: "white",
        anchor: "center",
        align: "top",
      },
      tooltip: {
        callbacks: {
          label: function (context: { raw: string | null }) {
            let label = context.raw || "";
            if (context.raw !== null) {
              label += " Hours Played";
            }
            return label;
          },
        },
      },
    },
  };

  const labels = [
    "Your Playtime",
    "Average Player Playtime",
    "Median Player Playtime",
  ];

  const data = {
    labels: labels,
    datasets: [
      {
        label: "Playtime",
        data: [
          (userTotalMinutesPlayed / 60).toFixed(1),
          (medianMinutesPlayed / 60).toFixed(1),
          (averageMinutesPlayed / 60).toFixed(1),
        ],
        backgroundColor: "#ff8d72",
      },
    ],
  };

  return (
    <div className="TopGameCard">
      {medianMinutesPlayed > 0 ? (
        <Card className="TopGameCard__card">
          <Card.Body className="TopGameCard__card-body">
            <div className="TopGameCard__text-div">
              <p className="TopGameCard__primary-text-left">
                Top Game Breakdown in Hours
              </p>

              <img
                src={gameImageUrl}
                className="img-fluid shadow-4"
                alt="game logo"
              ></img>
            </div>
            <p className="TopGameCard__secondary-text-left">
              Your Most Played Game is{" "}
              <b className="TopGameCard__bold">{`${game}`}</b>
            </p>
            <div className="TopGameCard__bar-div">
              <Bar options={options as any} data={data} />
            </div>
          </Card.Body>
        </Card>
      ) : (
        <BounceLoader color="#00f2c3"></BounceLoader>
      )}
    </div>
  );
};

export default TopGameCard;
