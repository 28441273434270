import React from "react";
import { Helmet } from "react-helmet-async";
import ToolCard from "../../components/Tool Card/ToolCard";
import "./Tools.css";

const Tools = () => {
  return (
    <div className="Tools">
      <Helmet>
        <title>Tools to beat video game addiction.</title>
        <meta
          name="description"
          content="Check out our favorite tools to beat video game addiction and finally stop wasting so much time on video games."
        ></meta>
        <link rel="canonical" href="/tools"></link>
      </Helmet>
      <h1 className="Tools__text-highlighted">
        Want to actually meet your dreams?
      </h1>
      <br></br>
      <h4>
        The tools below compromise the perfect "stack" that have helped me stay
        consistent in working towards my goals, every day.
      </h4>
      <br></br>
      <p>
        By utilizing the strategies from the tools in this section you can
        greatly decrease your steam time.
      </p>
      <br></br>
      <p className="Tools__text">
        Disclosure: Some of the links below are affiliate links. This means
        that, at zero cost to you, I will earn an affiliate commission if you
        click through the link and finalize a purchase.
      </p>
      <div className="Tools__toolcards-div">
        <ToolCard
          tool="Atomic Habits"
          toolType="Book"
          rating="4.38/5.00 - Goodreads"
          toolDetails="If you could just pick one book to read that could make a serious difference in your life, I would choose this one."
          toolLinkText="Amazon"
          toolLink="https://amzn.to/3TdvGRy"
        ></ToolCard>
        <ToolCard
          tool="Digital Minimalism"
          toolType="Book"
          rating="4.07/5.00 - Goodreads"
          toolDetails="From the same author as Atomic Habits, this book speaks on how low our attention spans have now become in the digital world and how we can counteract it."
          toolLinkText="Amazon"
          toolLink="https://amzn.to/3CtME7p"
        ></ToolCard>

        <ToolCard
          tool="Pursuit Goal Journal"
          toolType="Planner"
          rating="4.6/5.0 - Amazon"
          toolDetails="A 6 month planner with a system to detail all of your goals and a strategy to complete them."
          toolLinkText="Amazon"
          toolLink="https://amzn.to/3eoWL5B"
        ></ToolCard>
      </div>
      <div className="Tools__toolcards-div">
        <ToolCard
          tool="Streaks (IOS/Mac)"
          toolType="Software"
          rating="4.8/5 - App Store"
          toolDetails="An app to help you track your habit streaks.  Since I have had this app I haven't broken a single habit yet."
          toolLinkText="Amazon"
          toolLink="https://www.google.com/"
        ></ToolCard>
        <ToolCard
          tool="Things (IOS/Mac)"
          toolType="Software"
          rating="4.8/5 - App Store"
          toolDetails="An easy to use app that helps you keep track of your day, manage projects, and make progress towards your goals."
          toolLinkText="Amazon"
          toolLink="https://www.google.com/"
        ></ToolCard>
      </div>
    </div>
  );
};

export default Tools;
